<template>
  <el-dialog
    :visible.sync="visible"
    title="提示"
    width="22%"
    :modal="false"
    :close-on-click-modal="false"
    :before-close="handleClose"
  >
    <div class="tips-text">
      余额不足，请选择支付方式
    </div>
    <div class="dialog-footer">
      <el-button @click="recharge">
        充值
      </el-button>
      <el-button type="primary" @click="getAccountInfo">
        其他方式
      </el-button>
    </div>
    <div class="dialog-footer bank">
      <el-button v-for="item in accountInfo" :key="item.id">
        {{ item.settleBankName }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { accountInfoPage } from '@/http/custApi/enterpriseInformation'
export default {
  props: {
    // 控制显隐
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      accountInfo: []
    }
  },
  created() {
  },
  methods: {
    handleClose() {
      this.$emit('update:visible', false)
    },
    // 获取账户信息
    getAccountInfo() {
      accountInfoPage({
        pageSize: 2,
        pageNum: 1
      }, res => {
        this.accountInfo = [...res.data.pageData]
      })
    },
    // 充值
    recharge() {
      this.$router.push({ path: '/enterpriseInformation/accountInformation' })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-dialog__wrapper {
  top: 12%
}
.el-dialog__body {
  text-align: center;
}
.tips-text {
  color: #E6A23C;
  padding-bottom: 20px;
}
.dialog-footer {
  display: flex;
  justify-content: space-around;
  .el-button {
    width: 98px;
  }
}
.bank .el-button {
    width: 40%;
    margin-top: 20px;
}
</style>
