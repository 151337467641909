<template>
  <!-- 采购计划管理 -->
  <div class="purchase-plan-list">
    <!-- 搜索筛选 -->
    <FormSearch :getdata="getdata" :reset="true" :form-inline="formInline" :search-form-item-arr="formItemArr" />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="addRow">
          添加
        </el-button>
      </div>
      <Table :operation-button="operationButton" :item-data="itemData" :list-data="listData" :loading="loading" />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
    <SelectAccount ref="selectAccount" :money="paymentObj.money" :button-loading="paymentButtonLoading" @getAccount="getAccount" />
    <payment-prompt v-if="agreementConfig.visible" :visible.sync="agreementConfig.visible" />
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination'
import SelectAccount from '@/components/SelectAccount'
import paymentPrompt from './children/paymentPrompt'
import { agentPurchasePlanGetDepositAmountFirst, agentPurchasePlanPage, agentPurchasePlanCancel, agentPurchasePlanGetDepositPaymentUrl, agentPurchasePlanDepositPayment } from '@/http/custApi/purchasePlanManage'
export default {
  components: {
    FormSearch,
    Table,
    Pagination,
    paymentPrompt,
    SelectAccount
  },
  data() {
    return {
      formInline: {
        pageSize: 10,
        pageNum: 1,
        supplierCmpName: '',
        fundsCmpName: '',
        planStatus: ''
      },
      formItemArr: [
        { type: 'input', label: '合作企业', value: 'supplierCmpName' },
        { type: 'input', label: '代采机构', value: 'fundsCmpName', width: '120' },
        { type: 'select', label: '状态', pLabel: 'dictName', pValue: 'dictId', value: 'planStatus', width: '120', child: this.$store.getters.getDictionaryItem('planStatus') }
      ],
      itemData: [
        { label: '采购计划单号', prop: 'planNo', width: 160 },
        { label: '合作企业', prop: 'supplierCmpName', width: 120 },
        { label: '代采机构', prop: 'fundsCmpName', width: 180 },
        { label: '货款金额', prop: 'totalPayment', width: 120, type: 'money' },
        { label: '保证金比例(%)', prop: 'depositRate', width: 120, unit: '%' },
        // { label: '代采费率(%)', prop: 'agentRate', width: 100 },
        { label: '结算日期', prop: 'settleDate', width: 120 },
        { label: '创建时间', prop: 'createTime', width: 140 },
        { label: '状态', prop: 'planStatus', width: 120, child: this.$store.getters.getDictionaryItem('planStatus') }
      ],
      listData: [],
      operationButton: [
        { num: 1, val: 'planStatus', bType: 'primary', label: '保证金支付', handleEvent: this.marginPayment },
        { num: 2, val: 'planStatus', bType: 'primary', label: '保证金支付', handleEvent: this.marginPaymentABC },
        { num: 1, val: 'planStatus', bType: 'primary', label: '取消', handleEvent: this.cancelTable },
        { bType: 'primary', label: '详情', handleEvent: this.viewInfoTable }
      ],
      loading: false,
      total: 0,
      paymentObj: {},
      agreementConfig: {
        visible: false,
        data: {}
      },
      paymentButtonLoading: false
    }
  },
  created() {
    if (this.$route.query.planStatus) this.formInline.planStatus = this.$route.query.planStatus
  },
  methods: {
    // 获取数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      agentPurchasePlanPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.listData.forEach(item => {
          item.settleDate = item.settleDate + '号'
        })
        this.total = res.data.total
      })
    },
    // 添加
    addRow() {
      this.$router.push({ path: '/purchasePlanManage/purchasePlanAdd' })
    },
    marginPayment(row) {
      agentPurchasePlanGetDepositAmountFirst(row.id, res => {
        this.paymentObj = {
          fundsCmpCode: row.fundsCmpCode,
          id: row.id,
          money: res.data,
          planNo: row.planNo
        }
        this.$refs.selectAccount.dialogTableVisible = true
      })
    },
    // 农行支付中的按钮
    marginPaymentABC({ fundsCmpCode, planNo, settleBankId, id }) {
      agentPurchasePlanGetDepositPaymentUrl({
        custCmpCode: this.$getUserInfoParameter('cmpId'),
        firstFlag: 2,
        fundsCmpCode,
        id,
        planNo,
        settleBankId
      }, res => {
        window.open(res.data, '_blank')
      })
    },
    // 保证金支付
    async getAccount(row) {
      this.paymentButtonLoading = true
      if (row.settleBankId === 'ABC') {
        const { id, planNo, fundsCmpCode } = this.paymentObj
        agentPurchasePlanGetDepositPaymentUrl({
          custCmpCode: this.$getUserInfoParameter('cmpId'),
          firstFlag: 1,
          fundsCmpCode,
          id,
          planNo,
          settleBankId: row.settleBankId
        }, res => {
          this.getdata(true)
          window.open(res.data, '_blank')
          this.paymentButtonLoading = false
          this.$refs.selectAccount.dialogTableVisible = false
        })
        return
      }
      agentPurchasePlanDepositPayment({
        custCmpCode: this.$getUserInfoParameter('cmpId'),
        firstFlag: 1,
        settleBankId: row.settleBankId,
        ...this.paymentObj
      }, () => {
        this.$message.success('成功！')
        this.paymentButtonLoading = false
        this.$refs.selectAccount.dialogTableVisible = false
        this.getdata(true)
      }, err => {
        this.paymentButtonLoading = false
        if (err && err.code === 9999) {
          this.$refs.selectAccount.dialogTableVisible = true
        }
      })
    },
    // 详情
    viewInfoTable({ id }) {
      this.$router.push({ path: '/purchasePlanManage/purchasePlanInfo', query: { id }})
    },
    // 取消
    async cancelTable(row) {
      const confirm = await this.$confirm(`确认将取消采购计划${row.planNo}`, '提示', { type: 'warning' }).catch(err => err)
      if (confirm !== 'confirm') return
      agentPurchasePlanCancel({ planId: row.id }, res => {
        this.$message.success('成功！')
        this.getdata(true)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
